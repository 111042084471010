<template>
  <UiPage
    :title="$t('app.page.checkout')"
    :titleTemplate="`${$t('views.default.store.checkout.container')} - ${$t('app.page.checkout')}`"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="content">
        <CheckoutSteps
          :minimal="_minimal"
          :container_minimal="data.minimal_invest"
          :time="data.time_unit"
          :duration="data.duration"
          :minimal_reinvest="data.minimal_reinvest"
          :container="data"
          @choseMethod="method = $event"
          @updatePercent="customPercent = $event"
          @updateOncePercent="customOncePercent = $event"
        />

        <div class="content__rank">
          <div class="content__rank__sticky">
            <SkeletonAnimate
              :visible="preloader"
            />

            <p class="content__rank__sticky__label">
              {{ $t('views.default.store.checkout.your-purchase') }}
            </p>

            <Info
              :value="_data"
            />

            <div
              v-if="data?.products?.length > 0"
              class="content__rank__sticky__bottom"
            >
              <p class="label">
                {{ $t('components.ui.pages.pop-up.container-info.items') }}
              </p>

              <div class="container__items__list">
                <ProductCard
                  v-for="item in data?.products"
                  :key="item.id"
                  :value="item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import Info from '@/modules/store/Info.vue'
import ProductCard from '@/modules/store/ProductCard.vue'
import CheckoutSteps from '@/modules/checkout/CheckoutSteps.vue'

export default {
  components: {
    UiPage,
    SkeletonAnimate,
    BackButton,
    Info,
    ProductCard,
    CheckoutSteps
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      customPercent: null,
      customOncePercent: null,
      data: {},
      method: {}
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    _data () {
      return {
        ...this.data,
        percent: this.customOncePercent ? this.customOncePercent : this.data.percent,
        minimal_invest: this._minimal
      }
    },
    _minimal () {
      const method = this.method?.from_balance || 0
      const minimalInvoice = this.method?.minimal_invoice || 0

      if (method === 1) {
        return this.data.minimal_reinvest
      } else {
        if (this.data.minimal_invest < minimalInvoice) {
          return minimalInvoice
        } else {
          return this.data.minimal_invest
        }
      }
    }
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          `private/containers/${this.$route.params.id}`)

        if (resp.status === 200) {
          this.data = resp.data.data
        }
      } catch (err) {
        this.$router.push({ name: 'Store' })
        // this.$store.commit('SET_ERROR', true)
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;

  .content__rank {
    position: relative;

    .content__rank__sticky {
      position: sticky;
      top: 20px;
      min-width: 585px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
      border-radius: 15px;
      overflow: hidden;

      .content__rank__sticky__label {
        font-size: 18px;
        line-height: 100%;
        color: rgb(var(--color-dark));
        font-family: SemiBold;
      }

      .container__card {
        position: relative;
        height: 210px;
        width: 100%;
        margin: 20px 0;
        border-radius: 15px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .container__card__content {
          position: relative;
          height: 100%;
          width: 100%;
          padding: 20px;

          .container__card__content__top {
            display: flex;
            justify-content: space-between;

            h3 {
              line-height: 100%;
              color: #FFFFFF;
              font-family: SemiBold;
            }

            p {
              font-size: 12px;
              line-height: 100%;
              color: #FFFFFF;
              font-family: Medium;
            }
          }

          .container__card__content__center {
            width: 100%;
            margin: 20px 0 25px;

            p {
              margin-bottom: 7px;
              font-size: 12px;
              line-height: 100%;
              color: #FFFFFF;
              font-family: Medium;
            }

            .container__card__content__center__progress {
              height: 6px;
              width: 100%;
              background: rgba(255, 255, 255, .5);
              border-radius: 10px;

              .container__card__content__center__progress__filled {
                height: 100%;
                background: #FFFFFF;
                border-radius: 10px;
              }
            }
          }

          .container__card__content__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .container__card__content__bottom__info {
              margin-right: 15px;
              padding-right: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: rgba(0, 0, 0, .5);
              border-radius: 50px 5px 15px 5px;
              clip-path: polygon(100% 0, 100% calc(100% - 21px), calc(100% - 21px) 100%, 0 100%, 0 0);

              .container__card__content__bottom__info__total {
                position: relative;
                height: 100%;
                max-width: 112px;

                img {
                  position: relative;
                  height: 100%;
                  // object-fit: contain;
                }

                .container__card__content__bottom__info__total__content {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  h3 {
                    line-height: 100%;
                    text-align: center;
                    font-size: 24px;
                    font-family: SemiBold;
                  }

                  span {
                    font-family: Medium;
                    font-size: 12px !important;
                  }
                }
              }

              .container__card__content__bottom__info__item {
                height: 100%;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                  line-height: 100%;

                  &.big {
                    font-size: 18px;
                    line-height: 100%;
                    color: #FFFFFF;
                    font-family: SemiBold;
                  }

                  &.sub {
                    font-size: 12px;
                    line-height: 100%;
                    color: #9CA3B8;
                    font-family: Medium;
                  }
                }
              }
            }
          }
        }
      }

      .content__rank__sticky__bottom {
        display: flex;
        flex-direction: column;

        p.label {
          text-align: left;
          font-size: 18px;
          line-height: 100%;
          color: #202539;
          font-family: SemiBold;
        }

        .container__items__list {
          max-height: 400px;
          width: 100%;
          margin-top: 20px;
          padding-right: 15px;
          overflow: auto;
          scrollbar-color: rgba(var(--primary-color), 1) rgba(var(--primary-color), .2);
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: rgba(var(--primary-color), .2);
            border-radius: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(var(--primary-color), 1);
            border-radius: 6px;
          }

          .container__items__list__item {
            width: 100%;
            margin-bottom: 10px;
            padding: 3px;
            display: flex;
            border-radius: 5px;
            background: rgb(240, 242, 248);

            &:last-child {
              margin: 0;
            }

            img {
              height: 80px;
              width: 80px;
              border-radius: 5px;
              object-fit: cover;
            }

            .container__items__list__item__info {
              width: 100%;
              padding: 0 15px;

              .container__items__list__item__info__name {
                margin: 10px 0;
                color: rgb(var(--color-dark));
                font-family: SemiBold;
                font-size: 16px;
                line-height: 100%;
              }

              .container__items__list__item__info__grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .container__items__list__item__info__grid__item {
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-between;

                  .container__items__list__item__info__grid__item__name {
                    color: rgba(var(--text-color-light-gray));
                    font-size: 12px;
                    line-height: 100%;
                    font-family: Medium;
                    white-space: nowrap;
                  }

                  .container__items__list__item__info__grid__item__value {
                    color: rgb(var(--color-dark));
                    font-family: Medium;
                    font-size: 14px;
                    line-height: 100%;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1570px) {
  .content {
    flex-direction: column;

    .content__rank {
      .content__rank__sticky {
        position: relative;
        top: 0;
        min-width: 0;
        margin-bottom: 15px;
        padding: 20px 15px;
      }

      .content__rank__sticky__bottom {
        .container__items__list {
          max-height: none !important;
          padding: 0 !important;

          .container__items__list__item {
            align-items: center;

            img {
              height: 130px;
              // object-fit: cover !important;
            }

            .container__items__list__item__info {
              padding: 0 10px 10px;

              .container__items__list__item__info__grid {
                grid-template-columns: repeat(1, 1fr) !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .content {
    .content__rank {
      .content__rank__sticky {
        .container__card {
          .container__card__content {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 20px;

            .container__card__content__top {
              display: flex;
              justify-content: space-between;

              h3 {
                line-height: 100%;
                color: #FFFFFF;
                font-family: SemiBold;
              }

              p {
                font-size: 12px;
                line-height: 100%;
                color: #FFFFFF;
                font-family: Medium;
              }
            }

            .container__card__content__center {
              width: 100%;
              margin: 20px 0 25px;

              p {
                margin-bottom: 7px;
                font-size: 12px;
                line-height: 100%;
                color: #FFFFFF;
              }

              .container__card__content__center__progress {
                height: 6px;
                width: 100%;
                background: rgba(255, 255, 255, .5);
                border-radius: 10px;

                .container__card__content__center__progress__filled {
                  height: 100%;
                  background: #FFFFFF;
                  border-radius: 10px;
                }
              }
            }

            .container__card__content__bottom {
              flex-direction: column;

              .container__card__content__bottom__info {
                width: 100%;
                margin-right: 0;
                padding-right: 30px;
                justify-content: space-between;
                clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
              }

              button {
                max-width: none !important;
              }
            }
          }
        }
      }

      .content__rank__sticky__bottom {
        display: flex;
        justify-content: center;

        button {
          max-width: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .content {
    .content__rank__sticky__bottom {
      .container__items__list {
        .container__items__list__item {
          align-items: flex-start;
          flex-direction: column;

          img {
            height: auto !important;
            width: 100% !important;
          }

          .container__items__list__item__info {
            padding: 0 10px 10px !important;

            .container__items__list__item__info__grid {
              grid-template-columns: repeat(1, 1fr) !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .content {
    .content__rank {
      .content__rank__sticky {
        .container__card {
          .container__card__content {
            padding: 20px 10px;
          }
        }
      }
    }
  }

  .container__card__content__bottom__info {
    padding: 0 10px 0 0 !important;

    .container__card__content__bottom__info__item {
      p {
        &.big {
          font-size: 16px !important;
        }

        &.sub {
          font-size: 10px !important;
        }
      }
    }
  }
}
</style>
