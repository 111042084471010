<template>
  <div class="checkout">
    <div
      class="checkout__step"
      :class="{
        active : chooseMethod !== null && isContinue
      }"
    >
      <SkeletonAnimate
        :visible="preloader"
      />

      <div class="checkout__step__top">
        <div class="checkout__step__top__round">
          1
        </div>

        <div class="checkout__step__top__info">
          <p class="checkout__step__top__info__label">
            {{ $t('components.ui.pages.checkout.step.payment-method') }}
          </p>
        </div>
      </div>

      <div
        class="checkout__step__grid"
        :style="{
          'grid-template-columns': $store.state.window.to768 ? `repeat(${data?.currencies?.length}, 200px)` : ''
        }"
      >
        <button
          v-for="item in data?.currencies"
          :key="item.id"
          class="checkout__step__grid__item"
          :class="{
            selected: chooseMethod?.id === item.id
          }"
          @click="
            chooseMethod?.id !== item.id ?
              chooseMethod = item : chooseMethod = null,
              isContinue = false, $emit('choseMethod', item)
          "
          :disabled="item.from_balance === 1 && item.available < minimal_reinvest"
        >
          <div class="checkout__step__grid__item__top">
            <p>
              {{ item.name }}

              <template v-if="item.from_balance === 1">
                <br>

                <span>
                  {{ $t('components.ui.pages.checkout.step.percent-profit') }}
                </span>
              </template>

              <template v-else>
                <br>

                <span>
                  {{ $t('components.ui.pages.checkout.step.minimal-payment') }}:
                </span>

                <span style="white-space: nowrap;">
                  $ {{ $format.wallet(container_minimal > item.minimal_invoice ? container_minimal : item.minimal_invoice, ',') }}
                </span>
              </template>
            </p>

            <div
              class="checkout__step__grid__item__top__icon"
              :class="item.slug"
            >
              <img :src="item.image_url">
            </div>
          </div>

          <div class="checkout__step__grid__item__bottom">
            <button class="checkout__step__grid__item__bottom__button">
              {{ chooseMethod?.id === item.id ? $t('components.ui.pages.checkout.step.chosen') : $t('components.ui.pages.checkout.step.choose') }}

              <svg
                v-if="chooseMethod?.id === item.id"
                width="28"
                height="28"
                style="min-width: 28px; min-height: 28px;"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="14" r="14" fill="white"/>
                <path d="M8 13L13 18L21 10" stroke="#2F57E9" stroke-width="2" stroke-linecap="round"/>
              </svg>

              <svg
                v-else
                class="hovered"
                width="9"
                height="14"
                style="min-width: 9px; min-height: 14px;"
                viewBox="0 0 9 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L7 7L1 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </button>

            <div
              v-if="item.from_balance === 1 && item.available"
              class="checkout__step__grid__item__bottom__available"
            >
              <p class="sub">
                {{ $t('components.ui.pages.checkout.step.reinvest') }}
              </p>

              <p class="val">
                $ {{ $format.wallet(item.available?.toFixed(2), ',') }}
              </p>
            </div>
          </div>

          <div
            v-if="item.from_balance === 1 && item.available < minimal_reinvest"
            class="not"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0H12V6L6 12H0V6L6 0Z" fill="#DA2828" fill-opacity="0.2"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12H6L0 18V24H6L12 18V24H18L24 18V12H18L24 6V0H18L12 6V12ZM12 12H18L12 18V12Z" fill="#DA2828" fill-opacity="0.2"/>
              <g clip-path="url(#clip0_969_7881)">
              <path d="M18 7.37503C18 6.61652 17.3835 6 16.625 6H7.37503C6.61652 6 6 6.61652 6 7.37503V16.625C6 17.3835 6.61652 18 7.37503 18H16.625C17.3835 18 18 17.3835 18 16.625V7.37503Z" fill="#DA2828"/>
              <path d="M9.59961 9.6001L14.3996 14.4001M14.3996 9.6001L9.59961 14.4001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_969_7881">
              <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
              </clipPath>
              </defs>
            </svg>

            <p>
              {{ $t('components.ui.pages.checkout.step.reinvest-error') }} <span>$ {{ $format.wallet(minimal_reinvest, ',') }}</span>
            </p>
          </div>
        </button>
      </div>

      <div class="checkout__step__flex">
        <BlueButton
          style="max-width: 250px;"
          :text="$t('components.ui.pages.checkout.step.continue')"
          :disabled="chooseMethod === null || isContinue"
          @click="continueStep()"
        />
      </div>
    </div>

    <div
      class="checkout__step next"
      :class="{
        unactive: chooseMethod === null || !isContinue
      }"
      ref="checkout"
    >
      <SkeletonAnimate
        :visible="preloader"
      />

      <div class="checkout__step__top">
        <div class="checkout__step__top__round">
          2
        </div>

        <div class="checkout__step__top__info">
          <p class="checkout__step__top__info__label">
            {{ $t('components.ui.pages.checkout.step.investment-amount') }}
          </p>

          <p
            v-if="!$store.state.window.to768"
            class="checkout__step__top__info__sub"
          >
            {{ $t('components.ui.pages.checkout.step.min') }}

            <span>
              $ {{ $format.wallet(minimal, ',') }}
            </span>
          </p>
        </div>
      </div>

      <p
        v-if="$store.state.window.to768"
        class="checkout__step__top__info__sub"
      >
        {{ $t('components.ui.pages.checkout.step.min') }}

        <span>
          $ {{ $format.wallet(minimal, ',') }}
        </span>
      </p>

      <div
        v-if="chooseMethod && isContinue"
        class="checkout__step__content"
        ref="input"
      >
        <div class="checkout__step__content__input">
          <button
            class="checkout__step__content__input__button"
            :disabled="currencyToNumber <= minimal"
            @click="currency = `$ ${currencyToNumber - 10}`"
          >
            <svg
              width="20" height="4"
              style="min-width: 20px; min-height: 4px;"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2H18" stroke="#2F57E9" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </button>

          <input
            type="text"
            max="999999"
            v-model="currency"
          >

          <button
            class="checkout__step__content__input__button"
            @click="currency = `$ ${currencyToNumber + 10}`"
          >
            <svg
              width="20" height="20"
              style="min-width: 20px; min-height: 20px;"
              viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 2V18M2 10H18" stroke="#2F57E9" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </button>
        </div>

        <div class="checkout__step__content__tabs">
          <TabButton
            v-if="minimal <= 50"
            :text="'$ 50'"
            :value="50"
            :active="currencyToNumber"
            @click="currency = '$ 50'"
          />

          <TabButton
            v-if="minimal <= 100"
            :text="'$ 100'"
            :value="100"
            :active="currencyToNumber"
            @click="currency = '$ 100'"
          />

          <TabButton
            v-if="minimal <= 500"
            :text="'$ 500'"
            :value="500"
            :active="currencyToNumber"
            @click="currency = '$ 500'"
          />

          <TabButton
            v-if="minimal <= 1000"
            :text="'$ 1,000'"
            :value="1000"
            :active="currencyToNumber"
            @click="currency = '$ 1 000'"
          />

          <TabButton
            v-if="minimal <= 5000"
            :text="'$ 5,000'"
            :value="5000"
            :active="currencyToNumber"
            @click="currency = '$ 5 000'"
          />
        </div>

        <div
          v-for="(item, index) in data.stocks"
          :key="index"
          class="checkout__step__content__info"
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="2" fill="#2F57E9" fill-opacity="0.2"/>
            <path d="M17.8618 14.464V13.3193H19.4874C19.718 13.3193 19.9205 13.19 20.0105 12.979C20.1033 12.7681 20.064 12.5347 19.9065 12.3659C19.9065 12.3659 14.7877 6.84497 14.7877 6.845C14.4606 6.48014 13.8485 6.48258 13.5193 6.84497C13.5193 6.84497 8.40053 12.3659 8.40053 12.3659C8.0607 12.71 8.33711 13.3388 8.82238 13.3193H10.448V20.3506C10.448 20.6628 10.7039 20.9187 11.0189 20.9187H13.0552C11.7931 17.7718 14.4662 14.1445 17.8618 14.464V14.464Z" fill="#2F57E9"/>
            <path d="M17.5312 15.0127C15.2053 15.0127 13.3125 16.9055 13.3125 19.2314C13.5446 24.8281 21.5187 24.8265 21.75 19.2314C21.75 16.9055 19.8572 15.0127 17.5312 15.0127ZM15.1406 17.8252C15.1747 16.5272 17.0755 16.5274 17.1094 17.8252C17.0753 19.1232 15.1745 19.123 15.1406 17.8252ZM16.0406 21.1186C15.7792 21.3753 15.3852 20.9809 15.6441 20.7193C15.644 20.7193 19.019 17.3443 19.019 17.3443C19.2786 17.0857 19.6797 17.4837 19.4156 17.7437C19.4156 17.7436 16.0406 21.1186 16.0406 21.1186V21.1186ZM18.9375 21.6221C17.6395 21.588 17.6397 19.6871 18.9375 19.6533C20.2355 19.6873 20.2353 21.5882 18.9375 21.6221Z" fill="#2F57E9"/>
            <path d="M16.5469 17.825C16.536 17.2703 15.712 17.2708 15.7031 17.8251C15.7121 18.3793 16.5361 18.3797 16.5469 17.825Z" fill="#2F57E9"/>
            <path d="M18.9355 20.2158C18.3808 20.2247 18.3809 21.0508 18.9355 21.0596C19.4896 21.0492 19.4895 20.2261 18.9355 20.2158Z" fill="#2F57E9"/>
          </svg>

          <!-- {{ $t('components.ui.pages.checkout.step.bonus', { percent: item.percent, value: $format.wallet(item.amount, ',') }) }} -->
          {{ $t('components.ui.pages.checkout.step.bt', { value: $format.wallet(item.amount, ',') }) }}
        </div>

        <div class="checkout__step__content__profit">
          <div class="checkout__step__content__profit__item">
            <p class="checkout__step__content__profit__item__label">
              {{ $t('components.ui.pages.checkout.step.weekly-profit') }}
            </p>

            <p class="checkout__step__content__profit__item__val">
              $ {{
                $format.wallet(
                  rawardsCalculate.weekly,
                  ','
                )
              }}
            </p>
          </div>

          <div class="checkout__step__content__profit__item">
            <p class="checkout__step__content__profit__item__label">
              {{ $t('components.ui.pages.checkout.step.monthly-profit') }}
            </p>

            <p class="checkout__step__content__profit__item__val">
              $ {{
                $format.wallet(
                  rawardsCalculate.monthly,
                  ','
                )
              }}
            </p>
          </div>

          <div class="checkout__step__content__profit__item">
            <p class="checkout__step__content__profit__item__label">
              {{ $t('components.ui.pages.checkout.step.total-profit') }} ({{ _getYieldPeriod(time, duration) }}) <!-- ({{ $t(`components.ui.pages.store.container-card.${time}`, { n: duration }) }}) -->
            </p>

            <p class="checkout__step__content__profit__item__val">
              $ {{
                $format.wallet(
                  rawardsCalculate.annual,
                  ','
                )
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="checkout__step__bottom">
        <div class="checkout__step__bottom__total">
          <template v-if="currencyToNumber >= 1">
            <p class="checkout__step__bottom__total__label">
              {{ $t('components.ui.pages.checkout.step.total') }}
            </p>

            <p class="checkout__step__bottom__total__count">
              {{ currency }}
            </p>
          </template>
        </div>

        <BlueButton
          style="max-width: 250px;"
          :text="$t('components.ui.pages.checkout.step.pay')"
          :loading="loading || calculateLoading"
          :disabled="
            loading || currencyToNumber < minimal || calculateLoading ||
            (chooseMethod?.from_balance === 1 && chooseMethod?.available < currencyToNumber)
          "
          @click="submit()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlueButton from '@/ui/buttons/BlueButton.vue'
import TabButton from '@/ui/buttons/TabButton.vue'
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'

export default {
  components: {
    BlueButton,
    TabButton,
    SkeletonAnimate
  },
  props: {
    minimal: {
      type: Number,
      default: 100
    },
    duration: {
      type: Number,
      default: 0
    },
    time: {
      type: String,
      default: ''
    },
    minimal_reinvest: {
      type: Number,
      default: 0
    },
    container_minimal: {
      type: Number,
      default: 0
    },
    container: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      loading: false,
      calculateLoading: false,

      debounce: null,

      data: {},
      rewards: {},
      rawardsCalculate: {
        weekly: 0,
        monthly: 0,
        annual: 0,
        percent: 0,
        annual_percent: 0
      },

      chooseMethod: null,
      isContinue: false,

      currency: '$ 100'
    }
  },
  computed: {
    currencyToNumber () {
      var currency = this.currency.slice(1).replace(/\D/g, '')
      return Number(currency)
    }
  },
  deactivated () {
    this.chooseMethod = null
    this.isContinue = false
    this.currency = `$ ${this.minimal}`
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    currency () {
      const numberCurrency = Number(this.currency.slice(1).replace(/\D/g, ''))
      var currency = numberCurrency > 999999 ? 999999 : numberCurrency
      this.currency = `$ ${String(currency).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

      if (this.currency && this.chooseMethod) {
        this.debounceCalculateFetch()
      }
    },
    minimal () {
      this.currency = `$ ${this.minimal}`
    },
    chooseMethod () {
      if (this.currency && this.chooseMethod) {
        this.debounceCalculateFetch()

        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: this.chooseMethod.name
              },
              products: [{
                name: this.container.name,
                id: this.container.id,
                price: this.container_minimal > this.chooseMethod.minimal_invoice
                  ? this.container_minimal : this.chooseMethod.minimal_invoice,
                variant: this.container?.color?.class || '??'
              }]
            }
          }
        })
      }
    }
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          `/private/checkout?container_id=${this.$route.params.id}`)

        if (resp.status === 200) {
          this.data = resp.data.data
          this.rewards = resp.data.data.rewards
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    async submit () {
      this.loading = true

      try {
        const formData = new FormData()
        formData.append('currency_id', this.chooseMethod.id)
        formData.append('container_id', this.$route.params.id)
        formData.append('amount', this.currencyToNumber)

        const resp = await this.$axios.post(
          '/private/checkout', formData)

        if (resp.status === 200 && resp.data.success === true) {
          const data = resp.data.data

          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            ecommerce: {
              purchase: {
                actionField: {
                  id: resp.data?.data?.rewards?.order_id || this.container.id
                },
                products: [{
                  name: this.container.name,
                  id: this.container.id,
                  price: this.currencyToNumber,
                  variant: this.container?.color?.class || '??'
                }]
              }
            }
          })

          if (data.rewards.finished) {
            this.$pop.up('successfully')

            setTimeout(() => {
              this.$router.push({ name: 'StoreContainers' })
            }, 1500)
          } else {
            this.$store.commit('PRELOADER_GLOBAL_EDIT', true)
            this.$router.push({ name: 'Payment', params: { order: data.rewards.order_id } })
          }
        } else if (resp.status === 200 && resp.data.success === false) {
          const data = resp.data.data

          if (data.status === 'time_limit') {
            this.$pop.up({ name: 'checkoutTimeLimit', seconds: data.left })
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.$store.dispatch('fetchBalance', this.$axios)
        this.chooseMethod = null
        this.loading = false
      }
    },
    async calculateFetch () {
      if (this.chooseMethod?.id) {
        try {
          this.calculateLoading = true

          const resp = await this.$axios.get(
            `/private/checkout/calculate?container_id=${this.$route.params.id}&amount=${this.currencyToNumber}&currency_id=${this.chooseMethod?.id}`)

          if (resp.status === 200) {
            this.rewards = resp.data.data.rewards
            this.mathProfit(this.rewards)
          }
        } catch (err) {
          throw new Error(err)
        } finally {
          this.calculateLoading = false
        }
      }
    },
    mathProfit (percent) {
      this.rawardsCalculate = {
        weekly: percent.weekly.toFixed(2),
        monthly: percent.monthly.toFixed(2),
        annual: percent.annual.toFixed(2),

        percent: percent.percent.toFixed(2),

        annual_percent: percent.annual_percent
      }

      this.$emit('updatePercent', this.rewards.annual_percent)
      this.$emit('updateOncePercent', this.rewards.percent)
    },
    debounceCalculateFetch () {
      this.calculateLoading = true
      clearTimeout(this.debounce)

      this.debounce = setTimeout(async () => {
        await this.calculateFetch()
      }, 400)
    },
    _getYieldPeriod (timeUnit, duration) {
      if (timeUnit === 'day' && (duration === 365 || duration === 364)) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'week' && duration === 52) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'month' && duration === 12) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      }

      return this.$t(`components.ui.pages.store.container-card.${timeUnit}`, { n: duration })
    },
    continueStep () {
      this.isContinue = true

      try {
        setTimeout(() => {
          this.$refs.input.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' })
        }, 50)
      } catch (error) {
        throw new Error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout {
  width: 100%;
  margin-right: 20px;

  .checkout__step {
    position: relative;
    margin-bottom: 10px;
    padding: 20px;
    background: white;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
    border-radius: 15px;
    overflow: hidden;

    &.active {
      .checkout__step__top {
        .checkout__step__top__round {
          background: #577FFF;
          color: white;
        }
      }
    }

    &.unactive {
      .checkout__step__top {
        .checkout__step__top__round {
          background: rgba(var(--primary-color), .2);
          color: rgba(var(--text-color-light));
        }

        .checkout__step__top__info {
          .checkout__step__top__info__label {
            color: rgba(var(--text-color-light));
          }
        }
      }

      .checkout__step__top__info__sub {
        color: rgba(var(--text-color-light));

        span {
          color: rgba(var(--text-color-light));
        }
      }

      .checkout__step__content {
        display: none;
      }

      .checkout__step__bottom {
        display: none;
      }
    }

    .checkout__step__top {
      display: flex;
      align-items: center;

      .checkout__step__top__round {
        height: 36px;
        width: 36px;
        min-width: 36px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(var(--primary-color), .2);
        border-radius: 50%;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        color: rgb(var(--color-dark));
        font-family: SemiBold;
      }

      .checkout__step__top__info {
        .checkout__step__top__info__label {
          margin-bottom: 4px;
          font-size: 18px;
          line-height: 100%;
          color: rgb(var(--color-dark));
          font-family: SemiBold;
        }
      }
    }

    .checkout__step__top__info__sub {
      font-size: 14px;
      line-height: 120%;
      color: rgba(var(--text-color-light-gray));
      font-family: Medium;

      span {
        color: rgba(var(--primary-color));
        font-size: 14px;
        line-height: 100%;
        font-family: inherit !important;
      }
    }

    .checkout__step__grid {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .checkout__step__grid__item {
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #F0F2F8;
        border-radius: 10px;
        border: 2px solid transparent;
        transition: .2s;

        &:disabled {
          position: relative;
          border: 0 solid transparent;
          cursor: default;

          &:hover {
            border: 0 solid transparent;

            .checkout__step__grid__item__bottom {
              button.checkout__step__grid__item__bottom__button {
                background: rgba(var(--primary-color), .2);
                color: rgb(var(--color-dark));

                svg.hovered path {
                  stroke: rgb(var(--color-dark));
                }
              }
            }
          }
        }

        .not {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 15px 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid #F0F2F8;
          border-radius: 10px;

          p {
            max-width: 226px;
            font-size: 12px;
            font-family: Medium;
            line-height: 130%;
            text-align: center;
            color: #202539;

            span {
              color: #FF0000;
              font-family: inherit;
            }
          }

          svg {
            margin-bottom: 10px;
          }
        }

        &.selected {
          background: rgba(var(--primary-color), .2);

          .checkout__step__grid__item__top {
            .checkout__step__grid__item__top__icon {
              background: rgba(var(--primary-color));

              &.BTC {
                background: #FF843F;
              }

              &.USDT {
                background: #55BE8D;
              }

              &.USDC {
                background: #2775CA;
              }

              &.ETH {
                background: #F6BF02;
              }

              &.LTC {
                background: #395D9B;
              }
            }
          }

          .checkout__step__grid__item__bottom {
            button.checkout__step__grid__item__bottom__button {
              padding: 4px 4px 4px 15px;
              background: rgba(var(--primary-color));
              color: white;
            }
          }

          &:hover {
            border: 2px solid transparent;

            .checkout__step__grid__item__bottom {
              button.checkout__step__grid__item__bottom__button {
                background: rgba(var(--primary-color));
                color: white;
              }
            }
          }
        }

        &:hover {
          border: 2px solid rgba(var(--primary-color));

          .checkout__step__grid__item__bottom {
            button.checkout__step__grid__item__bottom__button {
              background: #577FFF;
              color: white;

              svg.hovered path {
                stroke: white;
              }
            }
          }
        }

        .checkout__step__grid__item__top {
          width: 100%;
          display: flex;
          justify-content: space-between;

          p {
            text-align: left;
            font-size: 16px;
            line-height: 100%;
            color: rgb(var(--color-dark));
            font-family: SemiBold;

            span {
              font-size: 12px;
              line-height: 100%;
              color: #2F57E9;
              font-family: Medium;
            }
          }

          .checkout__step__grid__item__top__icon {
            height: 40px;
            width: 40px;
            min-width: 40px;
            padding: 9px;
            display: flex;
            justify-content: center;
            background: #9CA3B8;
            border-radius: 50%;
            overflow: hidden;

            img {
              height: 100%;
            }
          }
        }

        .checkout__step__grid__item__bottom {
          width: 100%;
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          button.checkout__step__grid__item__bottom__button {
            height: 36px;
            width: 100%;
            max-width: 180px;
            padding: 4px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(var(--primary-color), .2);
            border-radius: 20px;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: rgb(var(--color-dark));
            font-family: SemiBold;
            transition: .2s;

            svg path {
              transition: .2s;
            }
          }

          .checkout__step__grid__item__bottom__available {
            width: 100%;
            margin-left: 15px;

            p {
              line-height: 100%;
              text-align: right;

              &.sub {
                margin-bottom: 5px;
                font-family: Medium;
                font-size: 12px;
                color: #787C8D;
              }

              &.val {
                font-family: SemiBold;
                font-size: 18px;
                color: #00B307;
              }
            }
          }
        }
      }
    }

    .checkout__step__flex {
      margin-top: 20px;
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid rgba(var(--border-line-color));
    }

    .checkout__step__content {
      width: 100%;
      margin-top: 20px;
      padding-top: 30px;
      border-top: 1px solid rgba(var(--border-line-color));

      .checkout__step__content__input {
        height: 46px;
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        background: #F0F2F8;
        border-radius: 30px;

        button.checkout__step__content__input__button {
          height: 36px;
          width: 36px;
          min-width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: rgba(var(--primary-color), .2);
          transition: .2s;

          svg path {
            transition: .2s;
          }

          &:hover {
            background: #577FFF;

            svg path {
              stroke: white;
            }
          }

          &:active {
            background: #586BB1;

            svg path {
              stroke: white;
            }
          }

          &:disabled {
            background: rgba(var(--primary-color), .2);

            svg path {
              stroke: rgba(156, 163, 184, 1);
            }
          }
        }

        input {
          width: 100%;
          min-width: none;
          margin: 0 15px;
          background: transparent;
          border: none;
          text-align: center;
          font-size: 18px;
          line-height: 100%;
          color: rgb(var(--color-dark));
          font-family: SemiBold;

          &:focus {
            outline: none;
          }
        }
      }

      .checkout__step__content__tabs {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .checkout__step__content__info {
        max-width: 590px;
        margin: 10px auto;
        padding: 8px;
        display: flex;
        align-items: center;
        background: rgba(47, 87, 233, .1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 120%;
        color: #2F57E9;
        font-family: Medium;

        svg {
          min-width: 30px;
          margin-right: 8px;
        }

        &:first-child {
          margin: 20px auto 10px !important;
        }

        &:last-child {
          margin: 10px auto 20px !important;
        }
      }

      .checkout__step__content__profit {
        width: 100%;
        margin-top: 20px;
        padding: 10px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-top: 1px solid rgba(var(--border-line-color));

        .checkout__step__content__profit__item {
          height: 80px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;

          &:first-child {
            border-right: 1px solid rgba(var(--border-line-color));
          }

          &:last-child {
            border-left: 1px solid rgba(var(--border-line-color));
          }

          p {
            text-align: center;

            &.checkout__step__content__profit__item__label {
              margin-bottom: 16px;
              font-size: 15px;
              line-height: 100%;
              color: rgb(var(--color-dark));
              font-family: Medium;
            }

            &.checkout__step__content__profit__item__val {
              font-size: 26px;
              line-height: 100%;
              text-align: center;
              color: rgb(var(--text-color-light-gray));
              font-family: SemiBold;
            }
          }
        }
      }
    }

    .checkout__step__bottom {
      width: 100%;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(var(--border-line-color));

      .checkout__step__bottom__total {
        .checkout__step__bottom__total__label {
          font-size: 12px;
          line-height: 100%;
          color: rgb(var(--color-dark));
          font-family: Medium;
        }

        .checkout__step__bottom__total__count {
          margin-top: 2px;
          font-size: 26px;
          line-height: 100%;
          color: rgba(var(--primary-color));
          font-family: SemiBold;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .checkout {
    margin-right: 0;

    .checkout__step {
      padding: 20px 0;

      &.next {
        padding: 20px 15px;

        .checkout__step__top {
          padding: 0;
        }
      }

      .checkout__step__top {
        padding: 0 15px;

        .checkout__step__top__round {
          height: 22px;
          width: 22px;
          min-width: 22px;
          margin-right: 10px;
        }
      }

      .checkout__step__top__info__sub {
        margin-top: 5px;
      }

      .checkout__step__content {
        .checkout__step__content__tabs {
          position: relative;
          left: -15px;
          width: calc(100% + 30px) !important;
          margin: 20px 0;
          padding: 0 15px 10px;
          width: 100%;
          justify-content: flex-start;
          overflow-x: auto;
          scrollbar-color: rgba(var(--primary-color), 0) rgba(var(--primary-color), 0) !important;
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background-color: rgba(var(--primary-color), 0)!important;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(var(--primary-color), 0) !important;
            border-radius: 4px;
          }

          button {
            white-space: nowrap;
          }
        }

        .checkout__step__content__info {
          max-width: 590px;
          margin: 10px auto 10px;

          svg {
            height: 22px;
            width: 22px;
            min-width: 22px;
            min-height: 22px;
            border-radius: 50%;
          }
        }

        .checkout__step__content__profit {
          width: 100%;
          margin-top: 15px;
          padding: 0 0 10px !important;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          border-top: none !important;

          .checkout__step__content__profit__item {
            height: auto !important;
            width: 100%;
            padding: 14px 2px !important;
            display: flex;
            flex-direction: row !important;
            align-content: flex-end;
            justify-content: space-between !important;
            border: none !important;

            p {
              &.checkout__step__content__profit__item__label {
                margin-bottom: 0 !important;
                color: #000000 !important;
                line-height: 100% !important;
                font-size: 14px;
              }

              &.checkout__step__content__profit__item__val {
                font-size: 14px !important;
              }
            }
          }
        }
      }

      .checkout__step__grid {
        margin-top: 20px;
        padding: 0 15px 10px;
        overflow-x: auto;

        .checkout__step__grid__item {
          min-height: 160px;

          .checkout__step__grid__item__top {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            p {
              margin-top: 10px;
            }

            .checkout__step__grid__item__top__icon {
              margin-right: 10px;
            }
          }

          .checkout__step__grid__item__bottom {
            flex-direction: column-reverse;

            .checkout__step__grid__item__bottom__available {
              margin-left: 0;
              margin-bottom: 15px;

              p {
                line-height: 100%;
                text-align: left;

                &.sub {
                  font-family: Medium;
                  font-size: 12px;
                  color: #787C8D;
                }

                &.val {
                  font-family: SemiBold;
                  font-size: 18px;
                  color: #00B307;
                }
              }
            }
          }
        }
      }

      .checkout__step__flex {
        margin: 10px 15px 0;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid rgba(var(--border-line-color));

        button {
          max-width: none !important;
        }
      }

      .checkout__step__bottom {
        width: 100%;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(var(--border-line-color));

        .checkout__step__bottom__total {
          width: 100%;
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .checkout__step__bottom__total__label {
            font-size: 12px;
            line-height: 100%;
            color: #202539;
            font-family: Medium;
          }

          .checkout__step__bottom__total__count {
            margin-top: 0;
            font-size: 26px;
            line-height: 100%;
            color: rgba(var(--primary-color));
            font-family: SemiBold;
          }
        }

        button {
          max-width: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .checkout {
    .checkout__step {
      .checkout__step__content {
        .checkout__step__content__profit {
          width: 100%;
          padding: 5px 0;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          border-top: 1px solid rgba(var(--border-line-color));

          .checkout__step__content__profit__item {
            height: 80px;
            padding: 10px 2px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: flex-start;

            &:first-child {
              border-right: 1px solid transparent;
            }

            &:last-child {
              border-left: 1px solid transparent;
            }

            p {
              text-align: center;

              &.checkout__step__content__profit__item__label {
                margin-bottom: 12px;
              }

              &.checkout__step__content__profit__item__val {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
